import React from 'react';
import Page1 from './Page1';
import { useTranslation } from 'react-i18next';
import Main from './Main';
import Information from './Information';
import { Element } from 'react-scroll';
import ReactGA from 'react-ga';


function Form(props) {
    // Google analytics
    ReactGA.pageview('/');

    const { t } = useTranslation();
    return <div className="container bg-primary">
        <Page1></Page1>
        <Element name="mainForm">
            <div className="row bg-light">
                <div className="col-12">
                    <div className="font-weight-light" data-section-name="two">
                        <div className="row mt-4">
                            <div className="col-12 h3 mt-4 text-primary font-weight-bold">
                                {t('home.create2')}
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-12">
                                {t('home.enter')}
                            </div>
                        </div>
                        <Main {...props}></Main>
                        <Information></Information>
                    </div>
                </div>
            </div>
        </Element>
    </div>;
}

export default Form;
