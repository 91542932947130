import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LogoType } from '../../images/Logotype-white.svg';

function Information(props) {
    const { t } = useTranslation();
    return <div className="bg-primary row">
        <div className="col-12">
            <div className="row">
                <div className="col-12 mt-5  pt-5 mb-2">
                    <LogoType />
                </div>
            </div>

            <div className="row">
                <div className="col-12 mt-3" id="information">
                    <div className="" id="headingQuestions">
                        <div className="collapsed text-light font-weight-light h5" data-toggle="collapse" data-target="#collapseQuestions" aria-expanded="true" aria-controls="collapseQuestions">
                            {t('home.questions')}
                        </div>
                    </div>
                    <div id="collapseQuestions" className="collapse" aria-labelledby="headingQuestions" data-parent="#information">
                        Some answers
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 mt-3" id="howWorks">
                    <div className="" id="headingHowWorks">
                        <div className="collapsed text-light font-weight-light h5" data-toggle="collapse" data-target="#collapseHowWorks" aria-expanded="true" aria-controls="collapseHowWorks">
                            {t('home.howworks')}
                        </div>
                    </div>
                    <div id="collapseHowWorks" className="collapse" aria-labelledby="headingHowWorks" data-parent="#howWorks">
                        Some answers
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 mt-3" id="isItFree">
                    <div className="" id="headingIsItFree">
                        <div className="collapsed text-light font-weight-light h5" data-toggle="collapse" data-target="#collapseIsItFree" aria-expanded="true" aria-controls="collapseIsItFree">
                            {t('home.isitfree')}
                        </div>
                    </div>
                    <div id="collapseIsItFree" className="collapse" aria-labelledby="headingIsItFree" data-parent="#isItFree">
                        Some answers
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 mt-3" id="about">
                    <div className="" id="headingAbout">
                        <div className="collapsed text-light font-weight-light h5" data-toggle="collapse" data-target="#collapseAbout" aria-expanded="true" aria-controls="collapseAbout">
                            {t('home.about')}
                        </div>
                    </div>
                    <div id="collapseAbout" className="collapse" aria-labelledby="headingAbout" data-parent="#about">
                        Brought to you by OneOverZero.
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 mt-3 mb-3">
                    <div className="collapsed font-weight-light h5 text-body" data-toggle="collapse" data-target="#collapseAbout" aria-expanded="true" aria-controls="collapseAbout">
                        © OneOverZero
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Information;
