import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInput } from '../../hooks/input-hook';


function Phone(props) {
    const { t } = useTranslation();
    const { bind:bindWorkPhone, setValue:setWorkPhone } = useInput('', (ev) =>  {
        const workPhone = ev.target.value;
        setWorkPhone(workPhone);
        props.setPhones({workPhone});
    });
    const { bind:bindHomePhone, setValue:setHomePhone } = useInput('', (ev) =>  {
        const homePhone = ev.target.value;
        setHomePhone(homePhone);
        props.setPhones({homePhone});
    });
    return (
        <div>
            <div className="" id="headingPhone">
                <div className="pl-0 d-flex align-items-center collapsed" data-toggle="collapse" data-target="#collapsePhone" aria-expanded="true" aria-controls="collapsePhone">
                    <span className="h1 font-weight-light mb-1 mr-2 collapsed-plus">+</span>
                    <span className="h6 font-weight-normal m-0">{t('home.phone2')}</span>
                </div>
            </div>
            <div id="collapsePhone" className="collapse" aria-labelledby="headingPhone" data-parent="#additionalFields">
                <div className="form-group">
                    <label htmlFor="workPhone">{t('home.workPhone')}</label>
                    <input type="tel" className="form-control form-control form-control-lg font-weight-light" id="workPhone" placeholder="+41435232732" pattern="[\+0-9 ]*" {...bindWorkPhone} />
                </div>
                <div className="form-group">
                    <label htmlFor="homePhone">{t('home.homePhone')}</label>
                    <input type="tel" className="form-control form-control form-control-lg font-weight-light" id="homePhone" placeholder="+41435232732" pattern="[\+0-9 ]*" {...bindHomePhone} />
                </div>
            </div>
        </div>
    )
}

function Address(props) {
    const { t } = useTranslation();
    const { bind:bindAddress, setValue:setAddress } = useInput('', (ev) =>  {
        const address = ev.target.value;
        setAddress(address);
        props.setAddress({address});
    });
    const { bind:bindCity, setValue:setCity } = useInput('', (ev) =>  {
        const city = ev.target.value;
        setCity(city);
        props.setAddress({city});
    });
    const { bind:bindPostcode, setValue:setPostcode } = useInput('', (ev) =>  {
        const postcode = ev.target.value;
        setPostcode(postcode);
        props.setAddress({postcode});
    });
    const { bind:bindCountry, setValue:setCountry } = useInput('', (ev) =>  {
        const country = ev.target.value;
        setCountry(country);
        props.setAddress({country});
    });
    return (
        <div>
            <div className="" id="headingAddress">
                <div className="pl-0 d-flex align-items-center collapsed" data-toggle="collapse" data-target="#collapseAddress" aria-expanded="true" aria-controls="collapsePhone">
                    <span className="h1 font-weight-light mb-1 mr-2 collapsed-plus">+</span>
                    <span className="h6 font-weight-normal m-0">{t('home.address')}</span>
                </div>
            </div>
            <div id="collapseAddress" className="collapse" aria-labelledby="headingAddress" data-parent="#additionalFields">
                <div className="">
                    <div className="form-group">
                        <label htmlFor="address">{t('home.street')}</label>
                        <input type="text" className="form-control form-control form-control-lg font-weight-light" id="address" placeholder="1 Time Square" {...bindAddress}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="city">{t('home.city')}</label>
                        <input type="text" className="form-control form-control form-control-lg font-weight-light" id="city" placeholder="New York" {...bindCity}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="postcode">{t('home.postcode')}</label>
                        <input type="text" className="form-control form-control form-control-lg font-weight-light" id="postcode" placeholder="123456" autoCorrect="off" autoCapitalize="off" {...bindPostcode}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="country">{t('home.country')}</label>
                        <input type="text" className="form-control form-control form-control-lg font-weight-light" id="country" placeholder="US" {...bindCountry}/>
                    </div>
                </div>
            </div>
        </div>
    );
}



function Additional(props) {
  return (
    <div className="accordion" id="additionalFields">
        <Phone {...props}></Phone>
        <Address {...props}></Address>
    </div>  
  );
}

export default Additional;
