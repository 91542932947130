import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './translations/en.json';
import de from './translations/de.json';
import fr from './translations/fr.json';
import it from './translations/it.json';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: en
    },
    de: {
        translation: de
    },
    fr: {
        translation: fr
    },
    it: {
        translation: it
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        whitelist: ['en', 'de', 'fr', 'it'],
        detection: { 
            caches: ['cookie']
        },
        resources,
        fallbackLng: "en",
        debug: true,
        load: 'languageOnly'
    });

export default i18n;