import { useState } from "react";

export const useInput = (initialValue, onChange = null) => {
  const [value, setValue] = useState(initialValue);
  const defaultOnChange = event => {
    setValue(event.target.value);
  }

  return {
    value,
    setValue,
    reset: () => setValue(""),
    bind: {
      value,
      onChange: onChange || defaultOnChange
    }
  };
};
