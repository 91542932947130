import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../../images/Logo.svg';
import { ReactComponent as LogoType } from '../../images/Logotype.svg';
import { Link } from 'react-scroll';

function Page1(props) {
    const { t } = useTranslation();
    return <div className="vh-100" data-section-name="one">
        <div className="row bg-white first-page d-flex flex-column justify-content-center align-items-center">
            <div className="d-flex flex-row mb-4">
                <Logo />
            </div>
            <div className="d-flex flex-row align-items-baseline mb-4">
                <LogoType />
            </div>
            <h5 className="font-weight-light text-muted">{t('home.subtitle')}</h5>
        </div>
        <div className="first-page-bottom">
        </div>
        <div className="bg-primary bg-gradient-primary">
            <div className="mx-4 h-100 d-flex flex-column">
                <div className="flex-column d-flex mt-4 pt-4 mb-3">
                    <h5 className="text-light  font-weight-normal text-center my-3">1. {t('home.store')}</h5>
                    <h5 className="text-light  font-weight-normal text-center my-3">2. {t('home.scan')}</h5>
                    <h5 className="text-light  font-weight-normal text-center my-3">3. {t('home.zapp2')}</h5>
                </div>
                <Link to="mainForm" smooth="easeInOutQuart" duration={800} delay={0}>
                    <button data-next-1
                        className="btn btn-light text-primary mb-auto btn-block justify-content-center  my-4">
                        <h5 className="m-2">{t('home.create')}</h5>
                    </button>
                </Link>
            </div>
        </div>
    </div>;
  
}

export default Page1;
